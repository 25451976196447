import { createItemsStore } from '@/utils/pinia/index.js'
import { useApiClient } from '@/composables/api-client'
import { useFantasyShopSetListStore } from '@/stores/fantasy/shop-set-list.js'

export const useFantasyShopCardsStore = createItemsStore('fantasy-shop-cards', {
  path: '/api/v1/fantasy/shop/cards/list',
  pagination: false,
  query: {
    expand: ['player.team', 'labels', 'cardMember']
  },
  extends: {
    actions: {
      async buyCard({ id, updateId }) {
        const apiClient = useApiClient()
        const fantasyShopSetListStore = useFantasyShopSetListStore()
        const data = await apiClient.post(
          `/api/v1/fantasy/shop/cards/${id}/buy`
        )
        fantasyShopSetListStore.setBoughtCard(updateId)
        return data
      },
      claimFreeCards() {
        const apiClient = useApiClient()
        return apiClient.post('/api/v1/fantasy/shop/cards/free')
      }
    }
  }
})
