import { createItemsStore } from '@/utils/pinia/index.js'
import { useApiClient } from '@/composables/api-client'

export const useFantasyShopSetListStore = createItemsStore(
  'fantasy-shop-set-list',
  {
    path: '/api/v1/fantasy/shop/sets',
    query: {
      expand: ['cards.player.team', 'cards.labels', 'cards.cardMember'],
      perPage: 4
    },
    extends: {
      actions: {
        async buySet({ setId, gradeId }) {
          const apiClient = useApiClient()
          const body = { grade_id: gradeId }
          const data = await apiClient.post(
            `/api/v1/fantasy/shop/sets/${setId}/buy`,
            { body }
          )
          this.setBought(setId)
          return data
        },
        setBought(setId) {
          const set = this.items.find(item => item.id === setId)
          if (set) {
            set.can_buy = false
            set.cards = set.cards.map(card =>
              Object.assign(card, { cardMember: {} })
            )
          }
        },
        setBoughtCard(cardId) {
          const set = this.items.find(
            set => set.cards.findIndex(card => card.id === cardId) > -1
          )
          if (set) {
            const card = set.cards.find(card => card.id === cardId)
            set.can_buy = false
            card.cardMember = {}
          }
        }
      }
    }
  }
)
